#details {
    padding-top: 7rem;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .col-description {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 991px) {
        .col-description {
            text-align: center;
        }
    }
}