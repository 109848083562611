.button {
    font-size: 14px;
    display: inline-block;
    position: relative;
    border-radius: 3px;
    padding: 1.2rem 16px;
    font-weight: bold;
    line-height: 1;
    z-index: 1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0px;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
    }
}

.button-hero {
    background-image: linear-gradient(135deg, $primary-color 0%, $secondary-color 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(135deg, $primary-color 0%, $secondary-color 100%);
    border-image-slice: 1;
    border-radius: 3px;
    cursor: pointer;

    &::after {
        background-image: linear-gradient(135deg, $primary-color 0%, $secondary-color 100%);
    }

    &:hover {
        -webkit-text-fill-color: $white-color;
        text-decoration: none;

        &::after {
            width: 100%;
        }
    }
}