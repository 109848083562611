/* catamaran-100 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 100;
    src: url('catamaran-v14-latin-100.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-100.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-100.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-100.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-100.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-200 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 200;
    src: url('catamaran-v14-latin-200.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-200.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-200.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-200.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-200.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-200.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-300 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 300;
    src: url('catamaran-v14-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-300.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-regular - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 400;
    src: url('catamaran-v14-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-regular.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-500 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 500;
    src: url('catamaran-v14-latin-500.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-500.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-500.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-600 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 600;
    src: url('catamaran-v14-latin-600.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-600.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-600.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-700 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 700;
    src: url('catamaran-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-700.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-800 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 800;
    src: url('catamaran-v14-latin-800.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-800.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-800.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-800.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-800.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-800.svg#Catamaran') format('svg');
    /* Legacy iOS */
}

/* catamaran-900 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 900;
    src: url('catamaran-v14-latin-900.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('catamaran-v14-latin-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('catamaran-v14-latin-900.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('catamaran-v14-latin-900.woff') format('woff'),
        /* Modern Browsers */
        url('catamaran-v14-latin-900.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('catamaran-v14-latin-900.svg#Catamaran') format('svg');
    /* Legacy iOS */
}